@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.btn:focus {
  outline: none;
  box-shadow: none;
}
.erase {
  animation: eraseText 0.9s forwards;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 0;
}

.reveal {
  animation: revealText 2s forwards;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

@keyframes eraseText {
  from {
      width: 100%;
  }
  to {
      width: 0;
  }
}

@keyframes revealText {
  from {
      width: 0;
  }
  to {
      width: 100%;
  }
}


